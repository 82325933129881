<template>
    <div>
        <img src="~@/assets/images/icones/confirmation.png" class="mb-3" alt="" />
        <p class="modal-texto">{{ mensagem }}</p>
        <div class="btns">
            <button class="btn btn-laranja" @click="$emit('salvar')">Sim</button>
            <button class="btn btn-cancelar" @click="$emit('cancelar')">Não</button>
        </div>
    </div>
</template>

<script>
export default {
    name: "Confirmacao",
    props: ['mensagem', 'cancelar', 'salvar'],
};
</script>

<style lang="scss">
.mb-3 {
    margin-bottom: 1rem;
}

.modal-texto {
    margin-bottom: 2rem;
}

.btns {
    display: flex;
    gap: 20px;
}
</style>
