<template>
  <div id="modal">
    <div class="modal-bg">
      <div class="modal-fechar" @click.exact="fecharModal"></div>
      <div class="modal-conteudo">
        <div class="conteudo-mensagem">
          <Confirmacao v-if="tipo === 'confirmacao'" :mensagem="this.mensagem" @cancelar="fecharModal" @salvar="acao" />
          <div v-else>
            <p>Planilha cadastrada com sucesso.</p>
            <div class="container-fechar">
              <button class="btn btn-laranja" @click="fecharModal">
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Confirmacao from './tipos/Confirmacao.vue';

export default {
  name: "ModalMensagem",
  components: {
    Confirmacao,
  },
  data() {
    return {
      tipo: this.$store.state.modalMensagem.tipo,
      mensagem: this.$store.state.modalMensagem.mensagem
    }
  },
  methods: {
    acao() {
      this.$store.state.modalMensagem.func();
      this.fecharModal()
    },
    fecharModal() {
      const config = { ativado: false, tipo: "", mensagem: "", func: () => { } };
      this.$store.commit("SET_MODAL_MENSAGEM", config);
    },
  },
};
</script>

<style lang="scss">
#modal {
  .container-fechar {
    display: flex;
    width: 100%;
    justify-content: center;

    button {
      margin: 0px;
      margin-top: 25px;
    }
  }

  position: fixed;
  top: 0;
  left: 0;
  z-index: 300;
  display: flex;
  overflow-y: auto;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  padding: 50px 25px 70px 25px;

  @media (max-width: 800px) {
    padding: 0;
    width: 100%;
    height: 100%;
  }

  .modal-bg {
    position: relative;
    z-index: 1;
    margin: auto;

    @media (max-width: 800px) {
      height: 100%;
    }

    &:before {
      content: "";
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      position: fixed;
    }

    .modal-fechar {
      position: absolute;
      z-index: 1;
      top: 8px;
      right: 10px;
      width: 30px;
      height: 30px;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        transform: scale(1.1);
      }

      &:before,
      &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 4px;
        content: "";
        border-radius: 20px;
      }

      &:before {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }

    .modal-conteudo {
      position: relative;
      width: 100%;

      @media (max-width: 800px) {
        height: 100%;
      }
    }
  }

  .conteudo-mensagem {
    position: relative;
    box-sizing: border-box;
    width: 100vw;
    border-radius: 15px;
    max-width: 400px;
    height: auto;
    max-height: 400px;
    padding: 25px;
    text-align: center;

    h2 {
      font-size: 35px;
      text-transform: uppercase;
      margin-bottom: 20px;
    }

    p {
      font-size: 22px;
      line-height: 1.1em;
      word-wrap: break-word;
    }

    @media (max-width: 800px) {
      min-height: 0px;
      width: 100vw;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      border-radius: 0;
    }
  }
}
</style>
